const modalOverlay = document.querySelector('.js-modalOverlay');
const body = document.querySelector('body');

export function modal() {
  const modalTrigger = document.querySelectorAll('.js-modalTrigger');
  const modalTriggerList = Array.prototype.slice.call(modalTrigger, 0);

  modalTriggerList.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      // モーダル内に情報反映
      const modalData = targetBox.getAttribute('data-modal');

      // モーダル表示
      modalOpen(modalData, targetBox);
    });
  });

  // モーダル非表示
  modalClose();
}

function modalOpen(data, ele) {
  const modal = document.querySelectorAll('.js-modal');
  const modalList = Array.prototype.slice.call(modal, 0);

  modalList.forEach(targetBox => {
    targetBox.classList.remove('is-view');
    const modalTypeData = targetBox.getAttribute('data-modaltype');

    body.classList.add('is-fixed'); // bodyにclassを付与して背景固定
    modalOverlay.classList.add('is-view'); // 背景に黒透過を表示
    if (modalTypeData === data) {
      targetBox.classList.add('is-view');
    }

    // 詳細ページのスライダーをモーダル展開
    if (data == 'img') {
      const modalPic = document.querySelector('.js-modalPic');
      const modalPicId = document.getElementById('modalPic');
      const imgSrc = ele.getAttribute('src');
      console.log(imgSrc);

      if (modalPicId) {
        modalPicId.parentNode.remove();
      }

      const galleryCont = document.createElement('div');
      galleryCont.className = 'c-modalPic_cont';
      galleryCont.innerHTML = '<div id="modalPic" class="c-modalPic_box"><img src="' + imgSrc + '" alt=""></div>';

      modalPic.appendChild(galleryCont);
      modalPic.classList.add('is-view');
    }
  });
}

function modalClose() {
  const modalClose = document.querySelectorAll('.js-modalClose');
  const modalCloseList = Array.prototype.slice.call(modalClose, 0);

  modalCloseList.forEach(targetBox => {
    targetBox.addEventListener('click', () => {
      body.classList.remove('is-fixed');
      modalOverlay.classList.remove('is-view');
      targetBox.parentNode.classList.remove('is-view');
    });
  });
}
